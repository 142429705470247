import React, { useState, useEffect, useRef } from "react";
import "../style/VoiceRecorder.scss";
import { toast, ToastContainer } from "react-toastify";
import MicIcon from "@mui/icons-material/Mic";
import { L } from "../langauge/english.lang";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ConfirmationModal from "./ConfirmationModal";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { API, AUTH_HEADER } from "../constants/api.const";
import CircularProgress from "@mui/material/CircularProgress";

const VoiceRecorder = React.memo(({ setActiveStep, setPage }) => {
  setPage(L.RECORD);

  const [currentDuration, setCurrentDuration] = useState(0);
  const [duration, setDuration] = useState(0);
  const { state } = useLocation();

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [recordingInterval, setRecordingInterval] = useState(null);
  const [deleteModal, openDeleteModal] = useState(false);
  const [recordingData, setRecordingData] = useState({});
  const [recordingId, setRecordingId] = useState(0);
  const [recordingFetched, setRecordingFetched] = useState(false)

  const [audio, setAudio] = useState(false);
  const [recordingPlayerWidth, setRecordingPlayerWidth] = useState("0%");
  const audioRef = useRef(null);
  const [playerInterval, setPlayerInterval] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [processingStep, setProcessingStep] = useState("");

  const formatTime = (milliseconds) => {
    if (!milliseconds || isNaN(milliseconds) || milliseconds === Infinity) {
      return "00:00";
    }
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };




  const toggleAudioPlay = (e) => {
    if (e === true && audioRef?.current?.paused) {
      audioRef?.current?.play();
      const interval = setInterval(() => {
        const currentTime = audioRef.current?.currentTime || 0;
        const duration = recordingDuration / 1000;
        if (duration > 0) {
          setRecordingPlayerWidth(`${(currentTime / duration) * 100}%`);
        }
        if (audioRef?.current?.ended) {
          setAudio(false);
          clearInterval(interval);
        }
      }, 100);
      setPlayerInterval(interval);
    } else if (e === false && !audioRef?.current?.paused) {
      audioRef?.current?.pause();
      clearInterval(playerInterval);
    }
    setAudio(e);
  };


  // useEffect(()=>{
  //   if(audioUrl && !isLoading) setActiveStep(99)
  //     else setActiveStep(1)
  // }, [audioUrl, isLoading])

  useEffect(() => {
    return () => {
      if (recordingInterval) clearInterval(recordingInterval);
      if (playerInterval) clearInterval(playerInterval);
    };
  }, [recordingInterval, playerInterval]);

  const updateValues = (key, value) => {
    setRecordingData((data) => ({
      ...data,
      [key]: value,
    }));
  };

  const startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.start();
        setIsRecording(true);
        setIsPaused(false);
        setRecordingDuration(0);
        setAudioChunks([]);

        const interval = setInterval(() => {
          setRecordingDuration((prev) => prev + 1000);
        }, 1000);
        setRecordingInterval(interval);

        recorder.ondataavailable = (event) => {

          const chunks = [...audioChunks, event.data];
          setAudioChunks(chunks);
          const audioBlob = new Blob(chunks, { type: "audio/wav" });
          setAudioBlob(audioBlob);
          const url = URL.createObjectURL(audioBlob);
          setAudioUrl(url);
          clearInterval(interval);
          setRecordingInterval(null);
        };
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
        toast.error(
          "Failed to access microphone. Please check your permissions."
        );
      });
  };

  const processRecording = async (audioBlob, duration) => {
    setIsLoading(true);
    setProcessingStep("Initiating processing...");

    try {
      const transcribeFD = new FormData();
      transcribeFD.append(
        "file",
        new File([audioBlob], "recording.wav", { type: "audio/wav" })
      );
      const backendFD = new FormData();
      backendFD.append(
        "recording_file",
        new File([audioBlob], "recording.wav", { type: "audio/wav" })
      );
      backendFD.append("appointment", state.apt?.id);
      backendFD.append("recording_duration", Math.floor(duration / 1000));

      setProcessingStep("Saving recording...");
      const recordingResponse = await axios.post(
        API.POST_RECORDING,
        backendFD,
        AUTH_HEADER
      );
      const recordingId = recordingResponse?.data?.data?.id;
      updateValues("recordingId", recordingId);
      toast.success(L.MSG.CONVERSATION_SAVED);

      setProcessingStep("Analyzing with AI...");
      const transcribeResponse = await fetch(
        "https://aisummarizer.cloud/transcribe/audio-text/",
        {
          method: "POST",
          body: transcribeFD,
          headers: {
            accept: "application/json",
          },
        }
      );

      if (transcribeResponse.ok) {
        const data = await transcribeResponse.json();
        const transcription = data.transcription;
        const genderDict = data.gender_dict;

        updateValues("transcription", transcription);

        setProcessingStep("Analyzing with AI...");
        const processLlama3 = await fetch(
          "https://aisummarizer.cloud/transcript/process_transcript",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            body: JSON.stringify({
              transcript: transcription,
              model: "llama3",
              duration: duration / 1000,
              gender_dict: genderDict,
            }),
          }
        );

        if (processLlama3.ok) {
          const llama3Data = await processLlama3.json();
          const llama3Summary = llama3Data.summary;
          const llama3Codes = JSON.stringify(llama3Data.codes);

          const payload = {
            recording_duration: Math.floor(duration / 1000),
            consultation_summaries: [
              {
                ai_model_name: "llama3",
                summary_text: llama3Summary,
                code: llama3Codes,
              },
            ],
            transcript: transcription,
            is_recording_paused: false,
            is_recording_deleted: false,
          };
          updateValues("modelData", payload);

          setProcessingStep("Updating recording details...");
          await axios.patch(
            API.UPDATE_RECORDING + recordingId + "/",
            payload,
            AUTH_HEADER
          );

          updateValues("llama3Summary", llama3Summary);
          updateValues("llama3Codes", llama3Codes);

          toast.success("Processing completed successfully!");
        } else {
          throw new Error("Failed to process transcripts");
        }
      } else {
        throw new Error("Failed to transcribe audio");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
      setProcessingStep("");
      // setActiveStep(99)

    }
  };

  useEffect(() => {
    if (audioBlob && recordingDuration && !state.recordingId) {
      processRecording(audioBlob, recordingDuration);
    }
  }, [audioBlob]);

  useEffect(() => {
    console.log(recordingId, " recording id")
    if(recordingFetched) return
    if (state.recordingId && state.recordingId != 0 && !recordingId) {
      setRecordingId(state.recordingId);
      updateValues("recordingId" , state.recordingId)
      //setActiveStep(99)
    }
    if (recordingId && recordingId != 0 && !audioUrl){
      setRecordingFetched(true)
      
          axios
        .get(API.GET_RECORDINGS + recordingId +"/",AUTH_HEADER)
        .then((d) => {
         console.log(d)
         setRecordingDuration(d?.data?.data?.recording_duration*1000)

         axios
        .get(API.GET_RECORDINGS_FILE(recordingId), {
          ...AUTH_HEADER,
          responseType: "blob", 
        })
        .then((d) => {
          const blob = new Blob([d.data], { type: "audio/wav" }); 
          const url = URL.createObjectURL(blob); 
          setRecordingFetched(true)
          // setAudioBlob(blob)
          setAudioUrl(url)
  
      
          console.log(url); 
        })
        .catch((e) => {
          console.log(e);
        });

        })
        .catch((e) => {
          console.log(e);
        });
      }
  }, [recordingId]);

  const pauseRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      clearInterval(recordingInterval);
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder && mediaRecorder.state === "paused") {
      mediaRecorder.resume();
      setIsPaused(false);

      const interval = setInterval(() => {
        setRecordingDuration((prev) => prev + 1000);
      }, 1000);
      setRecordingInterval(interval);
    }
  };

  const triggerStopRecording = () => {

    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);

      setIsPaused(false);
      //setActiveStep(99)
      clearInterval(recordingInterval);
    }
  };

  const deleteRecording = (e) => {
    openDeleteModal(false);
    if (e === false) return;
    const recordingId = recordingData?.recordingId;
          navigate("/home/walkthrough/record", {
            state: { apt: { id: state.apt?.id, client_name: state.apt.client_name } },
          });

   setRecordingFetched(true)
    if (!recordingId) {
      setActiveStep(1);
      setMediaRecorder(null);
      setAudioChunks([]);
      setAudioUrl(null);
      setAudioBlob(null);
      setIsRecording(false);
      setIsPaused(false);
      setRecordingDuration(0);
      setRecordingInterval(null);
      setRecordingData({});
              setRecordingId(0)
      
              navigate("/home/walkthrough/record", {
                state: { apt: { id: state.apt?.id, client_name: state.apt.client_name } },
              });
      return
    }
    axios
      .patch(
        API.UPDATE_RECORDING + recordingId + "/",
        { is_recording_deleted: true },
        AUTH_HEADER
      )
      .then((res) => {
        setActiveStep(1);
        setMediaRecorder(null);
        setAudioChunks([]);
        setAudioUrl(null);
        setAudioBlob(null);
        setIsRecording(false);
        setIsPaused(false);
        setRecordingDuration(0);
        setRecordingInterval(null);
        setRecordingData({});
        setRecordingId(0)
        if (res.status === 200) toast.success(L.MSG.RECORDING_DELETED);
      })
      .catch((e) => {
        console.error(e);
        toast.error("Failed to delete recording.");
      });
  };

  const moveNext = () => {
    setActiveStep(2);
    navigate("/home/walkthrough/review", {
      state: { ...state, recordingId: recordingData?.recordingId },
    });
  };

  // console.log(recordingDuration, recordingDuration);

  const handleTimeUpdate = () => {
    setCurrentDuration(audioRef.current?.currentTime);
    setDuration(audioRef.current?.duration);

  };

  const handleSeek = (e) => {
    if(audioRef.current) audioRef.current.currentTime = e.target.value;
    setCurrentDuration(e.target.value);
  };

  useEffect(() => {
    audioRef?.current?.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      audioRef?.current?.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [audioRef.current]);

  const formatDuration = (durationSeconds) =>{
    let duration = durationSeconds
    if(isNaN(duration) || duration == Infinity) return duration = recordingDuration/1000
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    const formattedSeconds = seconds.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <>
      <ToastContainer />
      <ConfirmationModal
        title={L.DELETE_MODAL.TITLE}
        text={L.DELETE_MODAL.TEXT}
        confirmButtonText={L.DELETE_MODAL.YES}
        cancelButtonText={L.DELETE_MODAL.NO}
        show={deleteModal || false}
        handleClose={deleteRecording}
      />
      <div className="voice-recorder">
        <div className="head">
          {!isRecording && !audioUrl && L.START_RECORDING}
          {isRecording && !isPaused && L.RECORDING + "..."}
          {isPaused && L.RESUME_RECORDING}
          {audioUrl && !isLoading && L.COMPLETED_RECORDING}
          {isLoading && "Processing..."}
        </div>

        <div className="controller">
          {!isRecording && !audioUrl && !isLoading && (
            <MicIcon onClick={startRecording} className="icon" />
          )}
          {isRecording && !isPaused && !isLoading && (
            <PauseIcon onClick={pauseRecording} className="icon recording" />
          )}
          {isPaused && !isLoading && (
            <PlayArrowIcon onClick={resumeRecording} className="icon" />
          )}

          {isRecording && !audioUrl && (
            <div className="time">{formatTime(recordingDuration)}</div>
          )}

          {audioUrl && !isLoading && (
            <div className="audio-display">
              <audio
                src={audioUrl}
                ref={audioRef}
                // onLoadedMetadata={() => {
                //   if (audioRef.current) {
                //     setRecordingDuration(
                //       audioRef.current.duration * 1000
                //     );
                //   }
                // }}
                hidden
              />
              {!audio && (
                <PlayArrowIcon
                  onClick={() => toggleAudioPlay(true)}
                  className="icon-mini"
                />
              )}
              {audio && (
                <PauseIcon
                  onClick={() => toggleAudioPlay(false)}
                  className="icon-mini"
                />
              )}
              {/* <div className="slider">
                <div
                  className="pad"
                  style={{
                    width: recordingPlayerWidth,
                  }}
                ></div>
              </div> */}

              <div className="timer">{formatDuration(parseInt(currentDuration))}</div>

              <input
                type="range"
                min="0"
                className="sliderInput"
                max={parseInt(duration)}
                value={parseInt(currentDuration)}
                onChange={handleSeek}
              />
              <div className="timer">{formatDuration(parseInt(recordingDuration/1000))}</div>
            </div>
          )}

          {isLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
              <p>{processingStep}</p>
            </div>
          )}
        </div>

        <div className="footer">
          {(isRecording || audioUrl) && !isLoading && (
            <>
              <DeleteIcon
                className="btn"
                onClick={() => openDeleteModal(true)}
              />
              {!audioUrl && (
                <SaveIcon onClick={triggerStopRecording} className="btn" />
              )}
              {audioUrl && (
                <ArrowRightAltIcon className="btn" onClick={moveNext} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
});

export default VoiceRecorder;
