import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import { API, AUTH_HEADER } from "../constants/api.const";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import KeyboardVoiceOutlinedIcon from "@mui/icons-material/KeyboardVoiceOutlined";
import "../style/home.scss";
import { L } from "../langauge/english.lang";
import Agenda from "./Agenda";
import { toast } from "react-toastify";

const MainHome = ({ privacyMode, setFormData, setActiveStep }) => {
  const [todo, setTodo] = useState([]);
  const [toReview, setToReview] = useState([]);

  const formatDate = (dateString, options) => {
    if (!dateString) return "";
    try {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-In", options).format(date);
    } catch (e) {
      return "";
    }
  };

  const dt = new Date();
  const today = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${API.GET_APPOINTMENTS}?is_scheduled=true&date=${today}`,
          AUTH_HEADER
        )
        .then((res) => {
          if (res && res.data) setTodo(res.data.data);
        })
        .catch((e) => console.log(e));

      axios
        .get(API.GET_RECORDINGS + `?is_reviewed=False`, AUTH_HEADER)
        .then((res) => {
          if (res && res.data) setToReview(res.data.data);
        })
        .catch((e) => console.log(e));
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const review = (recording) => {
    const apt = recording.appointment;
    setActiveStep(2);
    navigate("/home/walkthrough/review", {
      state: {
        apt: {
          id: apt.id,
          client_name:
            apt.patient.first_name +
            (apt.patient.last_name ? " " + apt.patient.last_name : ""),
        },
        recordingId: recording.id,
        consultation: recording.consultation,
      },
    });
  };

  const handleSubmitData = () => {
    const el = document.getElementById("clientNameInputField");
    const value = el.value;
    if (!value) {
      toast.error("Enter client name first");
      return;
    }
    console.log(value);
    axios
      .post(API.POST_APPOINTMENT, { client_name: value }, AUTH_HEADER)
      .then((res) => {
        console.log(res);
        if (res.status !== 200) {
          toast.error("Some error occurred while adding the client");
          return;
        }
        setActiveStep(1);
        navigate("/home/walkthrough/record", {
          state: { apt: { id: res?.data?.data?.id, client_name: value } },
        });
      })
      .catch((e) => {
        console.log(e);
        toast.error("Some error occurred while adding the client");
      });
  };

  return (
    <div className="start-main">
      <div className="left">
        <div className="to-do">
          <div className="head">
            <h3> {L.TO_REVIEW} </h3>
          </div>
          <div className="list">
            {toReview.map((item) => (
              <div className="item" onClick={(e) => review(item)}>
                <div className="seg1" style={{ cursor: "pointer" }}>
                  <div className="name">
                    {privacyMode === false ? (
                      <div>
                        {item.appointment.patient.first_name}
                        {item.appointment.patient.last_name
                          ? " " + item.appointment.patient.last_name
                          : ""}
                      </div>
                    ) : (
                      <div style={{ textTransform: "uppercase" }}>
                        {item.appointment.patient.first_name[0]}
                        {item.appointment.patient.last_name
                          ? item.appointment.patient.last_name[0]
                          : ""}
                      </div>
                    )}
                  </div>
                  <div className="date">
                    {formatDate(item.appointment.appointment_time, {
                      dateStyle: "medium",
                      timeStyle: "short",
                    })}
                  </div>
                  <div className="date">
                    {formatDate(item.appointment.appointment_end_time, {
                      timeStyle: "short",
                    })}
                  </div>
                </div>
                {/* Other elements if any */}
              </div>
            ))}
          </div>
        </div>

        <div className="unplanned">
          <div className="head">
            <h3> {L.UNPLANNED_CONTACT} </h3>
            <div className="note">{L.NO_CLIENT}</div>
          </div>

          <div className="details">
            <input
              className="input"
              id="clientNameInputField"
              placeholder={L.CLIENT_NAME}
            />
            <KeyboardVoiceOutlinedIcon
              className="mic"
              onClick={handleSubmitData}
            />
          </div>
        </div>
      </div>

      <div className="right">
        <Agenda privacyMode={privacyMode} />
      </div>
    </div>
  );
};

export default MainHome;
