import { API_URI } from "../environment";
import { TOKEN_KEY } from "./key.const";

export const API = {
    GET_APPOINTMENTS : API_URI+'/api/consulations/appoinment/',
    POST_APPOINTMENT : API_URI+'/api/consulations/appoinment/',

    GET_RECORDINGS : API_URI+'/api/consulations/recording/',
    GET_RECORDINGS_FILE : (id) => API_URI+`/api/consulations/recording-file/${id}/`,
    POST_RECORDING : API_URI+'/api/consulations/recording/',
    UPDATE_RECORDING : API_URI+'/api/consulations/recording/',

    GET_PROFILE : API_URI+'/api/auth/',
    UPDATE_PROFILE : API_URI+'/api/auth/',

    MICROSOFT_HANDLE_LOGIN : API_URI+'/api/auth/microsoft_handle_login/',
    MICROSOFT_LOGIN : API_URI+'/api/auth/microsoft_login/',


}
const adminStaticToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5NTE0MDYwLCJpYXQiOjE3MjY5MjIwNjAsImp0aSI6ImViZjg4MTRkMDEwNDRlMmY4ZTg4OWI0MDRkODVlMWYzIiwidXNlcl9pZCI6NH0.CtoR8wPwMeWtH0dxatnzASFFRuln11OePG9rvIhmUmE'
const staticToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzI5MTA3NzgxLCJpYXQiOjE3MjY1MTU3ODEsImp0aSI6ImZjNjM0ZWYzZjEzNTQ1OTI5ZTE4NTMzNDQwMDg2ZmI5IiwidXNlcl9pZCI6N30.rytvtThlEocjBoxImh1tOwcCnbAb9IOVptNAQw2wpmU';
export const SetAUTH_HEADER = () => {
    AUTH_HEADER =  {headers : {Authorization : 'Bearer ' + sessionStorage.getItem(TOKEN_KEY)  }}

}

 export var AUTH_HEADER =  {headers : {Authorization : 'Bearer ' + sessionStorage.getItem(TOKEN_KEY)  }}
// export var AUTH_HEADER =  {headers : {Authorization : 'Bearer ' + adminStaticToken }}

